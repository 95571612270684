import { AbstractControl, AsyncValidatorFn, FormControl, ValidationErrors } from "@angular/forms";
import { of } from "rxjs";
import { map, switchMap, delay } from "rxjs/operators";
import { AppConstants } from "./app.constants";
import { FuelReceivingApiService } from "./services/fuel-receiving-api.service";
import * as moment from "moment";
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';

/*compares two objects and return boolean value : start*/
// export function compareObjects(obj1, obj2) {
//     if (Object.keys(obj1).length !== Object.keys(obj2).length) {
//         return false
//     };
//     let keys = Object.keys(obj1);
//     for (let key of keys) {
//         if (typeof obj1[key] === 'object') {
//             if (!this.compareObjects(obj1[key], obj2[key])) {
//                 return false;
//             }
//         } else {
//             if (obj1[key] !== obj2[key]) {
//                 return false
//             };
//         }
//     }
//     return true;
// }

export function compareObjects(value, other) {

    // Get the value type
    var type = Object.prototype.toString.call(value);

    // If the two objects are not the same type, return false
    if (type !== Object.prototype.toString.call(other)) return false;

    // If items are not an object or array, return false
    if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

    // Compare the length of the length of the two items
    var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
    var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
    if (valueLen !== otherLen) return false;

    // Compare properties
    if (type === '[object Array]') {
      for (var i = 0; i < valueLen; i++) {
        if (compareItems(value[i], other[i]) === false) return false;
      }
    } else {
      for (var key in value) {
        if (value.hasOwnProperty(key)) {
          if (compareItems(value[key], other[key]) === false) return false;
        }
      }
    }

    // If nothing failed, return true
    return true;
}

// Compare two items
export function compareItems(item1, item2) {

    // Get the object type
    var itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
        if (!compareObjects(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {

        // If the two items are not the same type, return false
        if (itemType !== Object.prototype.toString.call(item2)) return false;

        // Else if it's a function, convert to a string and compare
        // Otherwise, just compare
        if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false;
        } else {
        if (item1 !== item2) return false;
        }

    }
}
/*compares two objects and return boolean value : end*/

/* checks if number has 2 decimals*/
export function checkForOneDecimalAndNumber(control: FormControl): ValidationErrors {
    return (control && control.value && control.value.toString().split('.').length > 2 || (control.value.toString().split('.').length > 1 && control.value.toString().split('.')[1].length > 1)) ? { decimalError: true } : null;
}

/* checks if number has 1 decimals */
export function checkForTwoDecimalsAndNumber(control: FormControl): ValidationErrors {
    return (control && control.value && control.value.toString().split('.').length > 2 || (control.value.toString().split('.').length > 1 && control.value.toString().split('.')[1].length > 2)) ? { decimalError: true } : null;
}

/* checks if value is number */
export function checkIfValueIsNumber(control: FormControl): ValidationErrors {
    return isNaN(control.value) ? { notValidNumber: true } : null;
}

/* checks if value contains alphanumber */
export function allowAlphaNumericOnly(control: FormControl) : ValidationErrors {
  return AppConstants.alphaNumericRegex.test(control.value.toString()) ? null : { notValidAlphaNumeric: true};
}

/**
 *
 * @param control
 * @returns ValidationErrors
 *  checks if value contains number
 */
export function checkIfValueContainsNumber(control: FormControl): ValidationErrors {
    return control.value && AppConstants.numberReqex.test(control.value) ? { hasNumber: true } : null;
}

/* checks if value has special characters */
export function checkIfValueContainsSpecialCharacters(control: FormControl): ValidationErrors {
    return control.value && AppConstants.specialCharReqex.test(control.value) ? { hasSpecialChars: true } : null;
}

export function checkDuplicateDocument(fuelReceivingService: FuelReceivingApiService): AsyncValidatorFn {
    return (control: AbstractControl) => {
        return of(control.value).pipe(
            delay(600),
            switchMap((value) => fuelReceivingService.checkForDuplicateDocument(value).pipe(map((data) => data?.content?.isDocumentExists ? { documentAlreadyExist: true } : null)))
        )
    }
}

export function checkForobj(control: FormControl): ValidationErrors {
    return typeof control.value === 'object' ? null : { notValid: true }
}

// Sump must be number greater than or equal to zero
export function checkIfValueGreaterThanOrEqualToZero(control: FormControl): ValidationErrors {
    return (control.value >= 0) ? null : {negativeError: true};
}

/**
 * use this function to check if value is not null and undefined,
 * we need to use this to avoid 0 falsy condition
 * @param value
 * @returns
 */
export function checkIfValueIsNullOrUndefined(value) {
    return value !== null && value !== undefined;
}

export const getISOString = (dateStr: string) => {
    const dateEnd = new Date(dateStr);
    return (new Date(dateEnd.getTime() - dateEnd.getTimezoneOffset() * 60000)).toISOString();
};


// use this function to change time from AM to PM.
export const setTimeToPM = (date: string) => {
    let dateTime = moment(new Date(date));
    // Set the time to 12:00 PM
    dateTime = dateTime.set({
      hour: 12,
      minute: 0
    });
    return dateTime
}

// use this function to filter data based on search input(event) within grid data i.e itemRowData
export function searchInGrid(event: any, itemRowData: any[], dataSource: MatTableDataSource<any>, paginator: MatPaginator, sort: MatSort, columnNames: string[]): void {
  // add date time column name here to convert to YYYY-MM-DD ex: default date time value "0001-01-01T00:00:00"
  const dateTimeColumns = ['serviceDateTime', 'startDate'];
  const filteredData = [];
  columnNames = columnNames.filter( columnName => columnName !== 'actions');

  if (event !== '') {
    // Convert MM/DD/YYYY to YYYY-MM-DD
    if(event.includes('/') || event.includes('-')) {
      event = moment(event).format(AppConstants.slashDateWithYearStart);
    }
    columnNames.forEach((columnName) => {
      const filteredValues = itemRowData.filter((rowValue) => {
        // convert itemRowData date & time value to YYYY-MM-DD
        if(dateTimeColumns.includes(columnName)) {
          rowValue[columnName] = moment(rowValue[columnName]).format(AppConstants.slashDateWithYearStart);
        }
        return rowValue && rowValue?.hasOwnProperty(columnName) && rowValue[columnName] && rowValue[columnName].toString().toLowerCase().includes(event.toString().toLowerCase()) && !filteredData.includes(rowValue)
      });

      filteredData.push(...filteredValues);
    });

    dataSource.data = filteredData;
  } else {
    dataSource.data = itemRowData;
  }
  resetGrid(dataSource.data, dataSource, paginator, sort);
}

export function resetGrid(itemRowData: any[], dataSource: MatTableDataSource<any>, paginator: MatPaginator, sort: MatSort) {
  dataSource.data = itemRowData;
  dataSource.paginator = paginator;
  dataSource.sort = sort;
}
