export class MessagesConstants {

    /* Validation Messages */
    public static readonly requiredMessage = 'This is a required field.';
    public static readonly futureDateMessage = 'Future dates are not a valid entry.';
    public static readonly maxCharactersMessage = 'Max 15 are allowed.';
    public static readonly minCharactersMessage = 'Min 1 is requied.';
    public static readonly maxSafValue = 'Max 100% are allowed.';
    public static readonly twoDecimalsMessage = 'Only two Decimals are allowed.';
    public static readonly oneDecimalMessage = 'Only one Decimal is allowed.';
    public static readonly onlyNumbersMessage = 'Only Numbers are allowed.';
    public static readonly maxCharTransferNumberMessage = 'Max 15 are allowed.';
    public static readonly invalidDateFormat = 'Please Enter MM/DD/YYYY';
    public static readonly totalQuantityMismatch = `Total quantity doesn't match the meter.`;
    public static readonly grossQuantityAndNetQuantityWarnigMessage = 'The difference between Gross and Net should not be more than 10%.';
    public static readonly onlyAlphaNumericMessage = 'Only Alphanumerics are allowed.';


    public static readonly min4CharactersMessage = 'Min 4 are allowed.';
    public static readonly max10CharactersMessage = 'Max 10 are allowed.';
    public static readonly dateFormatMessage = 'Please enter MM/DD/YYYY format.';
    public static readonly duplicateDocumentMessage = 'Duplicate Document#';

    public static readonly sumpNegativeNumberMessage = "Sump must be number greater than or equal to zero.";
    public static readonly negativeNumberMessage = "Please enter valid physical reading.";
    public static readonly overCapacityReading = "Reading exceeds Capacity, please enter valid reading.";

    public static readonly measurementTypeRequiredMessage = "Please select Actual or Book from the dropdown."
    public static readonly timeOffLessThanTimeOnError = 'Time Off must be Equal to Time On or Not greater than current fuel day.';
    public static readonly sameMeterReadingsError = 'Meter Start and End Readings should be different.';

    public static readonly capacityMustBeGreterThanZero = "Capacity value must be greater than 0(Zero).";

    public static readonly meterStartMustBeGreaterThanMeterEndReading = "Meter-Start reading must be greater than Meter-End reading."
    public static readonly meterReadingsTwelveDigits= "Meter readings allow  total of 12 digits with 10 whole number and 2 decimals";
    public static readonly onACAircraftServiceDateIsGreaterThanServiceDate = "On Aircraft Service date is greater than Service date.";
    /* Alert Messages */
    public static readonly gravityRagneMessage = 'Please enter an API Density between 37 and 51.';
    public static readonly c_TempratureRangeMessage = 'Please enter a Temperature between -29 to 55.';
    public static readonly f_TempratureRangeMessage = 'Please enter a Temperature between -20 and 130.';
    public static readonly isSelectedDate90DaysAfter = 'Selected date is After 90 days.';
    public static readonly isSelectedDate90DaysBefore = 'Selected date is Before 90 days.';

    public static readonly closedUnitErrorMessage = "This unit is closed. Please re-open the open day fuel unit to edit.";
    public static readonly noTrasactionErrorMessage = "No transaction found";
    
    public static readonly inactiveOwnerErrorMessage = "Selected Owner is not active";
    public static readonly inactiveSupplierErrorMessage = "Selected Supplier is not active";
    public static readonly deletedSupplierErrorMessage = "Selected Supplier Fuel Type combination is deleted.";
    public static readonly noHydrantUnitsFound = "No Hydrant Units Found.";
    /* Confirmation Messages */
    

}
